* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul, ol, li {
  list-style: none;
}

* {
  font-family: Helvetica, sans-serif;
}
